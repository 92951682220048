import { request, urls } from "@/api";
import { keys } from "@/helper";

export default {
  namespaced: true,
  state: {
    notificationCount: {
      seen: 0,
      unseen: 0,
      total: 0,
    },

    serviceTypeSwitch: {
      show: false,
      selected: 0,
    },
  },

  getters: {
    notificationCount: (state) => state.notificationCount,
    serviceTypeSwitch: (state) => state.serviceTypeSwitch,
  },

  mutations: {
    GET_NOTIFICATION_COUNT(state) {
      const onSuccess = (res) => {
        state.notificationCount = res.data.data;
      };
      request(keys.GET, urls.notification.count, { onSuccess });
    },

    TOGGLE_SERVICE_TYPE_SWITCH(state, obj) {
      const { show } = obj;
      state.serviceTypeSwitch.show = show;
    },
  },

  actions: {
    getNotificationCount({ commit }, obj) {
      commit("GET_NOTIFICATION_COUNT", obj);
    },

    toggleServiceTypeSwitch({ commit }, obj) {
      commit("TOGGLE_SERVICE_TYPE_SWITCH", obj);
    },
  },
};
