<template>
  <v-app>
    <v-main>
      <snack-bar />
      <component :is="this.$route.meta.layout || 'div'">
        <router-view />
      </component>
      <v-snackbar
        v-model="notification.show"
        color="primary"
        :timeout="3000"
        right
        multi-line
        transition="slide-y-reverse-transition"
      >
        <div class="d-flex align-center">
          <div v-if="notification.icon">
            <v-img :src="notification.icon" height="40px" width="40px" />
          </div>
          <div class="ml-3">
            <div class="font-weight-bold">
              {{ notification.title }}
            </div>
            <div v-if="notification.body" class="text-body-2">
              {{ notification.body }}
            </div>
          </div>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="notification.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <offline :value="offlineDialog" />
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase/app";
import { useOnline, useColorMode } from "@vueuse/core";
import { mapActions } from "vuex";

import Offline from "./components/offline.vue";
import snackBar from "./components/SnackBar/snackBar.vue";

export default {
  components: { snackBar, Offline },
  data: () => ({
    offlineDialog: false,
    notification: {
      show: false,
      title: "",
      body: "",
      icon: "",
    },
  }),

  computed: {
    online() {
      return useOnline().value;
    },
    systemThemeMode() {
      return useColorMode().value;
    },
  },

  watch: {
    online(value) {
      if (value) {
        this.offlineDialog = false;
      } else {
        this.offlineDialog = true;
      }
    },
    systemThemeMode() {
      this.setThemeMode("system");
    },
  },

  mounted() {
    const drawer_mini = localStorage.getItem("drawer_mini");
    this.setDrawerMini(drawer_mini === "true" ? true : false);

    const theme_mode = localStorage.getItem("theme_mode");
    if (theme_mode) this.setThemeMode(theme_mode);
    else {
      localStorage.setItem("theme_mode", "system");
      this.setThemeMode("system");
    }

    const theme = localStorage.getItem("theme");
    if (theme) this.setTheme(theme);
    else {
      this.setTheme("default");
    }

    if (firebase.messaging.isSupported()) {
      this.$fcm.onMessage((payload) => {
        const notification = payload.notification;
        this.notification.title = notification.title;
        this.notification.body = notification.body ? notification.body : "";
        this.notification.icon = notification.icon ? notification.icon : "";
        this.notification.show = true;
        this.getNotificationCount();
      });
    }
  },
  methods: {
    ...mapActions({
      setTheme: "setTheme",
      setThemeMode: "setThemeMode",
      setDrawerMini: "setDrawerMini",
      getNotificationCount: "topNav/getNotificationCount",
    }),
  },
};
</script>

<style>
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th,
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  font-weight: bold !important;
  font-size: 14px !important;
  height: 62px !important;
  text-align: center !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  color: #333333 !important;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  color: #ffffff !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  color: #333333 !important;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  color: #ffffff !important;
}

.tab-width {
  width: 200px;
}

.pointer {
  cursor: pointer;
}

* {
  padding: 0;
  margin: 0;
}

html {
  overflow-y: auto !important;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #F1F1F1;
}

::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  border-radius: 20px;
}

</style>