export default [
  {
    key: "default",
    light: {
      primary: "#1976D2",
    },
    dark: {
      primary: "#2196F3",
    },
  },
  {
    key: "teal",
    dark: {
      primary: "#52c7b8",
    },
    light: {
      primary: "#00675b",
    },
  },
  {
    key: "cyan",
    dark: {
      primary: "#62efff",
    },
    light: {
      primary: "#008ba3",
    },
  },
  {
    key: "indigo",
    dark: {
      primary: "#757de8",
    },
    light: {
      primary: "#002984",
    },
  },
  {
    key: "purple",
    dark: {
      primary: "#d05ce3",
    },
    light: {
      primary: "#6a0080",
    },
  },
  {
    key: "pink",
    dark: {
      primary: "#ff6090",
    },
    light: {
      primary: "#b0003a",
    },
  },
  {
    key: "brown",
    dark: {
      primary: "#a98274",
    },
    light: {
      primary: "#4b2c20",
    },
  },
  {
    key: "blue_grey",
    dark: {
      primary: "#8eacbb",
    },
    light: {
      primary: "#34515e",
    },
  },
];
