export default {
  namespaced: true,
  state: {
    addCaseDialog: {
      show: false,
    },
  },
  getters: {
    addCaseDialog: (state) => state.addCaseDialog,
  },
  mutations: {
    TOGGLE_ADD_CASE_DIALOG(state, obj) {
      const { show } = obj;
      state.addCaseDialog.show = show;
    },
  },
  actions: {
    toggleAddCaseDialog({ commit }, obj) {
      commit("TOGGLE_ADD_CASE_DIALOG", obj);
    }
  },
};
