import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import PaymentIcon from "@/components/Icons/payment";
import PayoutIcon from "@/components/Icons/payout";
import ServiceIcon from "@/components/Icons/service";
import LedgerIcon from "@/components/Icons/ledger";

import PoorIcon from "@/components/Icons/poor";
import FairIcon from "@/components/Icons/fair";
import AverageIcon from "@/components/Icons/average";
import GoodIcon from "@/components/Icons/happy";
import ExcellentIcon from "@/components/Icons/excellent";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
  },
  icons: {
    values: {
      payment: {
        component: PaymentIcon,
      },
      payout: {
        component: PayoutIcon,
      },
      service: {
        component: ServiceIcon,
      },
      ledger: {
        component: LedgerIcon,
      },
      poor: {
        component: PoorIcon,
      },
      fair: {
        component: FairIcon,
      },
      average: {
        component: AverageIcon,
      },
      good: {
        component: GoodIcon,
      },
      excellent: {
        component: ExcellentIcon,
      },
    },
  },
});
