<template>
  <v-navigation-drawer
    app
    hide-overlay
    height="100%"
    width="260"
    class="elevation-3"
    v-model="drawer"
    :permanent="$vuetify.breakpoint.smAndUp"
    :temporary="$vuetify.breakpoint.xsOnly"
    :mini-variant="drawerMini"
    mini-variant-width="80"
  >
    <template v-slot:prepend>
      <v-list-item :class="drawerMini ? 'px-2' : ''" v-ripple class="pointer">
        <v-list-item-content>
          <v-img
            :src="require(`@/assets/images/${logo.sm}`)"
            alt="logo"
            width="24"
            @click="goToDashboard"
            v-if="drawerMini"
          />
          <v-img
            :src="require(`@/assets/images/${logo.lg}`)"
            alt="logo"
            height="55"
            @click="goToDashboard"
            v-else
          />
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider />

    <div class="my-2">
      <v-list v-for="item in computedNavLinks" :key="item.name" class="py-0">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              link
              :to="item.to"
              color="primary"
              active-class="active-nav"
              v-bind="attrs"
              v-on="on"
              @click="onNavClicked(item)"
            >
              <v-list-item-icon class="mx-0">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-3">
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </v-list>
    </div>

    <template v-slot:append>
      <div class="pa-2">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click.stop="drawerMini = !drawerMini"
              class="float-right"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="21"
                >mdi-chevron-{{ drawerMini ? "right" : "left" }}</v-icon
              >
            </v-btn>
          </template>
          <span>Click to {{ drawerMini ? "Expand" : "Collapse" }}</span>
        </v-tooltip>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      navLinks: [
        {
          name: "Dashboard",
          icon: "mdi-view-dashboard",
          to: "/",
          key: "dashboard",
        },
        {
          name: "Client Management",
          to: "/client-management",
          key: "client_management",
          icon: "mdi-account-multiple",
        },
        {
          name: "Case Management",
          to: "/case-management",
          key: "case_management",
          icon: "mdi-file-document",
        },
        {
          name: "Payment Management",
          to: "/payment-management",
          key: "payment_management",
          icon: "$.payment",
        },
        {
          name: "Payout Management",
          to: "/payout-management",
          key: "payout_management",
          icon: "$.payout",
        },
        {
          name: "Refund Management",
          to: "/refund-management",
          key: "refund_management",
          icon: "mdi-cash-refund",
        },
        {
          name: "TAT Management",
          to: "/tat-management",
          key: "tat_management",
          icon: "mdi-calendar-clock-outline",
        },
        {
          name: "Team Management",
          to: "/team-management",
          key: "team_management",
          icon: "mdi-account-group-outline",
        },
        {
          name: "University Management",
          to: "/university-management",
          key: "university_management",
          icon: "mdi-school-outline",
        },
        {
          name: "ECA Management",
          to: "/eca-management",
          key: "eca_management",
          icon: "mdi-book-education-outline",
        },
        {
          name: "FE/IR Management",
          to: "/fe-ir-management",
          key: "fe_ir_management",
          icon: "mdi-account-hard-hat-outline",
        },
        {
          name: "Vendor Management",
          to: "/vendor-management",
          key: "vendor_management",
          icon: "mdi-account-hard-hat-outline",
        },
        {
          name: "Service Management",
          to: "/service-management",
          key: "service_management",
          icon: "$.service",
        },
        {
          name: "Document Management",
          to: "/document-management",
          key: "document_management",
          icon: "mdi-file-document-multiple-outline",
        },
        {
          name: "Reports",
          to: "/reports",
          key: "reports",
          icon: "mdi-table",
        },
        {
          name: "Ledger",
          to: "/ledger",
          key: "ledger",
          icon: "$.ledger",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      logo: "getLogo",
      permissions: "getPermissions",
      drawer_mini: "drawerMini",
    }),

    drawerMini: {
      get() {
        return this.drawer_mini;
      },
      set(value) {
        this.setDrawerMini(value);
      },
    },

    computedNavLinks() {
      return this.$_.filter(this.navLinks, (nav) =>
        this.permissions.includes(nav.key)
      );
    },
  },

  methods: {
    ...mapActions({
      setDrawerMini: "setDrawerMini",
    }),

    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    goToDashboard() {
      this.$router.push({ name: "dashboard" });
    },

    onNavClicked(item) {
      this.$root.$emit("onNavClicked", item.key);
    },
  },
};
</script>
<style scoped>
.active-nav {
  border-right: 4px solid;
}
</style>