export default {
    namespaced: true,
    state: {
        isConfirm: { show: false, text: "", id: null, emit: "" },
    },
    getters: {
        getIsConfirm: (state) => state.isConfirm,
    },
    mutations: {
        TOGGLE_IS_CONFIRM(state, obj) {
            if (obj.show) {
                state.isConfirm.id = obj.id ? obj.id : null;
                state.isConfirm.text = obj.text ? obj.text : "";
                state.isConfirm.emit = obj.emit ? obj.emit : "";
            }
            state.isConfirm.show = obj.show;
        },
    },
    actions: {
        isConfirm({ commit }, item) {
            commit("TOGGLE_IS_CONFIRM", item);
        },
    },
};
