import Vue from "vue";
import Vuex from "vuex";
import Vuetify from "@/plugins/vuetify";
import { useColorMode } from "@vueuse/core";
import themes from "@/common/themes";
import _ from "lodash";

import snackBar from "./module/snackBar";
import clientManagement from "./module/clientManagement/index";
import caseManagement from "./module/caseManagement";
import tatManagement from "./module/tatManagement/index";
import admin from "./module/admin/index";
import stateManagement from "./module/admin/stateManagement";
import cityManagement from "./module/admin/cityManagement";
import app from "./module/app/index";

import dialogs from "@/store/module/Dialogs";
import drawers from "@/store/module/drawers";
import topNav from "@/store/module/topNav";
import dashboard from "@/store/module/dashboard";

import payouts from "@/store/module/payouts";
import payments from "@/store/module/payments";
import refund from "@/store/module/refund";
import additionalPayment from "@/store/module/caseManagement/additionalPayment";
import university from "@/store/module/university";
import ledger from "@/store/module/ledger";

Vue.use(Vuex);

// initial state
const state = {
  user: null,
  permissions: [],
  themeMode: "system",
  theme: "default",
  logo: {
    sm: "logo_sm_light.svg",
    lg: "logo_lg_light.svg",
  },
  drawerMini: false,
  countryList: [],
  stateList: [],
  cityList: [],
  serviceTypes: [],
};

const getters = {
  getUser: (state) => state.user,
  getPermissions: (state) => state.permissions,
  getThemeMode: (state) => state.themeMode,
  getTheme: (state) => state.theme,
  getLogo: (state) => state.logo,
  getCountryList: (state) => state.countryList,
  getStateList: (state) => state.stateList,
  getCityList: (state) => state.cityList,
  getServiceTypes: (state) => state.serviceTypes,
  drawerMini: (state) => state.drawerMini,
};

const mutations = {
  SET_USER(state, obj) {
    state.user = obj;
    state.permissions = obj.permissions;
  },

  SET_THEME_MODE(state, mode) {
    state.themeMode = mode;
    if (mode === "system") {
      const systemMode = useColorMode();
      mode = systemMode.value;
    }

    if (mode === "dark") {
      Vuetify.framework.theme.dark = true;
      state.logo.sm = "logo_sm_light.svg";
      state.logo.lg = "logo_lg_light.svg";
    } else {
      Vuetify.framework.theme.dark = false;
      state.logo.sm = "logo_sm_dark.svg";
      state.logo.lg = "logo_lg_dark.svg";
    }
  },

  SET_THEME(state, theme_key) {
    state.theme = theme_key;
    localStorage.setItem("theme", theme_key);

    const theme = _.find(themes, { key: theme_key });
    const name = theme.key;
    const dark = theme.dark;
    const light = theme.light;

    Object.keys(dark).forEach((i) => {
      Vuetify.framework.theme.themes.dark[i] = dark[i];
    });

    Object.keys(light).forEach((i) => {
      Vuetify.framework.theme.themes.light[i] = light[i];
    });

    Vuetify.framework.theme.themes.name = name;
  },

  SET_DRAWER_MINI(state, obj) {
    state.drawerMini = obj;
    localStorage.setItem("drawer_mini", obj ? "true" : "false");
  },

  SET_COUNTRY_LIST(state, list) {
    state.countryList = list;
  },

  SET_STATE_LIST(state, list) {
    state.stateList = list;
  },

  SET_CITY_LIST(state, list) {
    state.cityList = list;
  },

  SET_SERVICE_TYPES(state, list) {
    state.serviceTypes = list;
  },
};

const actions = {
  setUser({ commit }, obj) {
    commit("SET_USER", obj);
  },

  setThemeMode({ commit }, mode) {
    commit("SET_THEME_MODE", mode);
  },

  setTheme({ commit }, theme_key) {
    commit("SET_THEME", theme_key);
  },

  setDrawerMini({ commit }, obj) {
    commit("SET_DRAWER_MINI", obj);
  },

  setCountryList({ commit }, list) {
    commit("SET_COUNTRY_LIST", list);
  },

  setStateList({ commit }, list) {
    commit("SET_STATE_LIST", list);
  },

  setCityList({ commit }, list) {
    commit("SET_CITY_LIST", list);
  },

  setServiceTypes({ commit }, list) {
    commit("SET_SERVICE_TYPES", list);
  },
};

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {
    snackBar,
    clientManagement,
    app,
    caseManagement,
    tatManagement,
    admin,
    stateManagement,
    cityManagement,
    additionalPayment,
    university,
    payments,
    payouts,
    refund,
    dialogs,
    drawers,
    topNav,
    dashboard,
    ledger,
  },
});
