import AppLayout from "@/layout/AppLayout";
import AuthLayout from "@/layout/AuthLayout";

export default [
  {
    path: "/login",
    name: "login",
    meta: {
      isPublic: true,
      title: "Login",
      layout: AuthLayout,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/auth/login"),
  },
  {
    path: "/forget-password",
    name: "forgetPassword",
    meta: {
      isPublic: true,
      title: "Forget Password",
      layout: AuthLayout,
    },
    component: () =>
      import(/* webpackChunkName: "forget" */ "@/views/auth/forget"),
  },
  {
    path: "/reset-password/:uid/:token",
    name: "resetPassword",
    meta: {
      isPublic: true,
      title: "Reset Password",
      layout: AuthLayout,
    },
    component: () =>
      import(
        /* webpackChunkName: "set-password" */ "@/views/auth/set-password"
      ),
  },
  {
    path: "/",
    name: "dashboard",
    meta: {
      isPublic: false,
      layout: AppLayout,
      title: "Dashboard",
      key: "dashboard",
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/views/app/dashboard/dashboard"
      ),
  },
  {
    path: "/client-management",
    name: "",
    meta: {
      isPublic: false,
      title: "Client Management",
      layout: AppLayout,
      key: "client_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "clientMainRoute" */ "@/views/app/clientManagement/clientMainRoute"
      ),
    children: [
      {
        path: "",
        name: "clientManagement",
        meta: {
          isPublic: false,
          title: "Client Management",
          layout: AppLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/app/clientManagement/client"
          ),
      },
      {
        path: ":id",
        name: "clientDetail",
        meta: {
          isPublic: false,
          title: "Client Detail",
          layout: AppLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "clientDetail" */ "@/views/app/clientManagement/clientDetail"
          ),
      },
    ],
  },
  {
    path: "/case-management",
    name: "",
    meta: {
      isPublic: false,
      title: "Case Management",
      layout: AppLayout,
      key: "case_management",
    },
    component: () =>
      import(/* webpackChunkName: "case" */ "@/views/app/caseManagement/case"),
    children: [
      {
        path: "",
        name: "caseList",
        meta: {
          isPublic: false,
          title: "Case Management",
          layout: AppLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "list" */ "@/views/app/caseManagement/list"
          ),
      },
      {
        path: "add",
        name: "caseAdd",
        meta: {
          isPublic: false,
          title: "Case Management",
          layout: AppLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "case-add" */ "@/views/app/caseManagement/case-add"
          ),
      },
      {
        path: "edit/:id",
        name: "caseEdit",
        meta: {
          isPublic: false,
          title: "Case Management",
          layout: AppLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "case-edit" */ "@/views/app/caseManagement/case-edit"
          ),
      },
      {
        path: ":id",
        name: "caseDetail",
        meta: {
          isPublic: false,
          title: "Case Management",
          layout: AppLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "caseDetail" */ "@/views/app/caseManagement/caseDetail"
          ),
      },
    ],
  },
  {
    path: "/payment-management",
    name: "paymentManagement",
    meta: {
      isPublic: true,
      title: "Payment Management",
      layout: AppLayout,
      key: "payment_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "paymentManagement" */ "@/views/app/paymentManagement/paymentManagement"
      ),
  },
  {
    path: "/payout-management",
    name: "payoutManagement",
    meta: {
      isPublic: true,
      title: "Payout Management",
      layout: AppLayout,
      key: "payout_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "payoutManagement" */ "@/views/app/payoutManagement/payoutManagement"
      ),
  },
  {
    path: "/refund-management",
    name: "refundManagement",
    meta: {
      isPublic: true,
      title: "Refund Management",
      layout: AppLayout,
      key: "refund_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "refundManagement" */ "@/views/app/refundManagement/refundManagement"
      ),
  },
  {
    path: "/tat-management",
    name: "tatManagement",
    meta: {
      isPublic: true,
      title: "TAT Management",
      layout: AppLayout,
      key: "tat_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "tatManagement" */ "@/views/app/tatManagement/tatManagement"
      ),
  },
  {
    path: "/team-management",
    name: "teamManagement",
    meta: {
      isPublic: true,
      title: "Team Management",
      layout: AppLayout,
      key: "team_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "teamManagement" */ "@/views/app/admin/teamManagement/teamManagement"
      ),
  },
  {
    path: "/eca-management",
    name: "ecaManagement",
    meta: {
      isPublic: true,
      title: "ECA Management",
      layout: AppLayout,
      key: "eca_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "ecaManagement" */ "@/views/app/admin/ecaManagement/ecaManagement"
      ),
  },
  {
    path: "/university-management",
    name: "",
    meta: {
      isPublic: true,
      title: "University Management",
      layout: AppLayout,
      key: "university_management",
    },
    query: { page: 1, items: 12 },
    component: () =>
      import(
        /* webpackChunkName: "university" */ "@/views/app/admin/universityManagement/university"
      ),
    children: [
      {
        path: "",
        name: "universityManagement",
        meta: {
          isPublic: true,
          title: "University Management",
          layout: AppLayout,
          key: "university_management",
        },
        component: () =>
          import(
            /* webpackChunkName: "universityManagement" */ "@/views/app/admin/universityManagement/universityManagement"
          ),
      },
      {
        path: "/university-management/:id",
        name: "viewUniversityManagement",
        meta: {
          isPublic: true,
          title: "University Management",
          layout: AppLayout,
          key: "university_management",
        },
        component: () =>
          import(
            /* webpackChunkName: "viewUniversity" */ "@/views/app/admin/universityManagement/viewUniversity"
          ),
      },
    ],
  },
  {
    path: "/fe-ir-management",
    name: "feIrManagement",
    meta: {
      isPublic: true,
      title: "FE/IR Management",
      layout: AppLayout,
      key: "fe_ir_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "feIrManagement" */ "@/views/app/admin/feIrManagement/feIrManagement"
      ),
  },
  {
    path: "/vendor-management",
    name: "vendorManagement",
    meta: {
      isPublic: true,
      title: "Vendor Management",
      layout: AppLayout,
      key: "vendor_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "vendorManagement" */ "@/views/app/admin/vendorManagement/vendorManagement"
      ),
  },
  {
    path: "/service-management",
    name: "serviceManagement",
    meta: {
      isPublic: true,
      title: "Service Management",
      layout: AppLayout,
      key: "service_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "serviceManagement" */ "@/views/app/admin/serviceManagement/serviceManagement"
      ),
  },
  {
    path: "/document-management",
    name: "documentManagement",
    meta: {
      isPublic: true,
      title: "Document Management",
      layout: AppLayout,
      key: "document_management",
    },
    component: () =>
      import(
        /* webpackChunkName: "documentManagement" */ "@/views/app/admin/documentManagement/documentManagement"
      ),
  },
  {
    path: "/reports",
    name: "reports",
    meta: {
      isPublic: true,
      title: "Reports",
      layout: AppLayout,
      key: "reports",
    },
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/app/reports/reports"),
  },
  {
    path: "/ledger",
    name: "ledger",
    meta: {
      isPublic: true,
      title: "Ledger",
      layout: AppLayout,
      key: "ledger",
    },
    component: () =>
      import(/* webpackChunkName: "ledger" */ "@/views/app/ledger/ledger"),
  },
];
