import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import _ from "lodash";

// firebase
import firebase from "firebase/app";
import "firebase/messaging";

// sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import Croppa from "vue-croppa";
import VueZoomer from "vue-zoomer";
import LottieAnimation from "lottie-web-vue";

// custom components
import Loading from "@/components/Loading";

// custom utils
import { request, urls } from "@/api";
import { keys, values, utils, rules } from "@/helper";

if (firebase.messaging.isSupported()) {
  const firebaseConfig = {
    apiKey: "AIzaSyBwnF8vft2U1NSmAQinbXbEqRw-v0ey1gA",
    authDomain: "worldwide-transcripts.firebaseapp.com",
    projectId: "worldwide-transcripts",
    storageBucket: "worldwide-transcripts.appspot.com",
    messagingSenderId: "842572953180",
    appId: "1:842572953180:web:c2e4b9d0acc83998795899",
    measurementId: "G-968LZGT6GK",
  };

  firebase.initializeApp(firebaseConfig);
  Vue.prototype.$fcm = firebase.messaging();
}

if (process.env.VUE_APP_ENABLE_SENTRY === "true") {
  Sentry.init({
    Vue,
    environment: process.env.NODE_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [process.env.VUE_APP_BASE_URL, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

// custom prototype
Vue.prototype.$request = request;
Vue.prototype.$keys = keys;
Vue.prototype.$values = values;
Vue.prototype.$utils = utils;
Vue.prototype.$rules = rules;
Vue.prototype.$urls = urls;
Vue.prototype.$moment = moment;
Vue.prototype.$_ = _;

Vue.prototype.$forceCompute = function (
  computedName,
  forceUpdate /* default: true */
) {
  if (this._computedWatchers[computedName]) {
    this._computedWatchers[computedName].run();
    if (forceUpdate || typeof forceUpdate == "undefined") this.$forceUpdate();
  }
};

Vue.use(Croppa);
Vue.use(VueZoomer);
Vue.component("vue-lottie", LottieAnimation);
Vue.component("Loading", Loading);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
