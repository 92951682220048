<template>
  <div>
    <v-dialog
      transition="scroll-y-transition"
      scrollable
      persistent
      v-model="show"
      max-width="480px"
      content-class="rounded-xl"
    >
      <v-card :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex align-start justify-space-between">
          <div class="text-h6">Password Change</div>
          <v-icon @click="modalClosed" size="26">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form class="pt-4" lazy-validation ref="form">
            <v-text-field
              autofocus
              v-model="form.password"
              :disabled="loading"
              label="Current Password"
              :rules="[rules.required]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="!showPassword ? 'password' : 'text'"
              @click:append="showPassword = !showPassword"
              height="42px"
              class="mr-2"
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model="form.new_password"
              label="New Password"
              :rules="[rules.required]"
              :type="!new_showPassword ? 'password' : 'text'"
              :append-icon="new_showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="new_showPassword = !new_showPassword"
              height="42px"
              class="mr-2"
              outlined
              :disabled="loading"
              dense
            ></v-text-field>

            <v-text-field
              v-model="form.confirm_password"
              label="Confirm Password"
              :type="!confirm_showPassword ? 'password' : 'text'"
              :append-icon="confirm_showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="confirm_showPassword = !confirm_showPassword"
              @keyup.enter="passwordChanged"
              :rules="[rules.required, matchPassword]"
              height="42px"
              :disabled="loading"
              class="mr-2"
              outlined
              dense
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-capitalize btn-dark px-7 primary"
            height="40px"
            @click="submit"
            :loading="loading"
          >
            <span>Save</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";

export default {
  data() {
    return {
      rules: RULES,
      loading: false,
      showPassword: false,
      new_showPassword: false,
      confirm_showPassword: false,
      form: {
        password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      passwordChangeDialog: "dialogs/passwordChangeDialog",
    }),
    show: {
      get() {
        return this.passwordChangeDialog.show;
      },
      set(value) {
        this.togglePasswordChangeDialog({ show: value });
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      togglePasswordChangeDialog: "dialogs/togglePasswordChangeDialog",
    }),

    matchPassword(value) {
      if (value === this.form.new_password) {
        return true;
      } else {
        return "Password is not match";
      }
    },

    openModal() {},
    modalClosed() {
      this.togglePasswordChangeDialog({ show: false });
      this.$refs.form.reset();
      this.form = {
        password: "",
        new_password: "",
        confirm_password: "",
      };
      this.loading = false;
      this.showPassword = false;
      this.new_showPassword = false;
      this.confirm_showPassword = false;
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.modalClosed();
        };

        const onFinally = () => {
          this.loading = false;
        };

        let data = {
          password: this.form.password,
          new_password: this.form.new_password,
        };

        return this.$request(this.$keys.POST, this.$urls.auth.changePassword, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>
<style scoped>
.title-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.sub-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  opacity: 0.6;
}
</style>
 


