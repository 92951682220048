<template>
  <div>
    <div v-if="user">
      <!-- top navigation -->
      <TopNav @toggleLeftNav="toggleLeftNav" />

      <!-- right settings drawer -->
      <settings-drawer />

      <!-- left navigation -->
      <LeftNav ref="leftNav" />

      <!-- main content -->
      <v-container class="pa-6" fluid>
        <router-view />
      </v-container>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import { mapActions, mapGetters } from "vuex";

import SettingsDrawer from "@/components/SettingsDrawer";
import TopNav from "@/components/TopNav";
import LeftNav from "@/components/LeftNav";

export default {
  components: {
    SettingsDrawer,
    TopNav,
    LeftNav,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },

  beforeCreate() {
    const successHandler = (res) => {
      this.setUser(res.data.data);
    };
    this.$request(this.$keys.GET, this.$urls.auth.profile, {
      onSuccess: successHandler,
    });
  },

  async mounted() {
    this.getCountry();
    this.getState();
    this.getCity();
    this.getServiceTypes();

    await this.$utils.sleep(20);
    if (firebase.messaging.isSupported()) {
      this.$fcm
        .getToken({ vapidKey: process.env.VUE_APP_FCM_KEY })
        .then((token) => {
          if (token) {
            const data = {
              token: token,
              platform: "web",
            };

            this.$request(this.$keys.POST, this.$urls.auth.setFcmToken, {
              data: data,
            });
          }
        })
        .catch(() => {});
    }
  },
  methods: {
    ...mapActions({
      setCountryList: "setCountryList",
      setStateList: "setStateList",
      setCityList: "setCityList",
      setServiceTypes: "setServiceTypes",
      setUser: "setUser",
    }),

    toggleLeftNav() {
      if (this.$refs.leftNav) {
        this.$refs.leftNav.toggleDrawer();
      }
    },

    getCountry() {
      const params = {
        apply_pagination: false,
      };

      const successHandler = (res) => {
        this.setCountryList(res.data.data);
      };

      this.$request(this.$keys.GET, this.$urls.master.country.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getState() {
      const params = {
        apply_pagination: false,
      };

      const successHandler = (res) => {
        this.setStateList(res.data.data);
      };

      this.$request(this.$keys.GET, this.$urls.master.state.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getCity() {
      const params = {
        apply_pagination: false,
      };

      const successHandler = (res) => {
        this.setCityList(res.data.data);
      };

      this.$request(this.$keys.GET, this.$urls.master.city.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getServiceTypes() {
      const successHandler = (res) => {
        this.setServiceTypes(res.data.data);
      };

      this.$request(this.$keys.GET, this.$urls.master.service.typeList, {
        onSuccess: successHandler,
      });
    },
  },
};
</script>