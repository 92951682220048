<template>
  <v-dialog scrollable v-model="show" width="480px" content-class="rounded-xl">
    <Loading v-if="loading" />
    <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-end">
        <v-btn icon @click="modalClosed">
          <v-icon size="26"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center">
          <vue-lottie
            v-if="show && lottie"
            class="mb-6"
            :style="{ height: height, width: width }"
            :animationData="require(`@/assets/lotties/${lottie}.json`)"
            ref="lottie"
          />
        </div>
        <div class="text-center mb-8">
          <p class="text-h5 font-weight-bold">
            <slot name="title">Are you sure?</slot>
          </p>
          <p class="text-body-1">
            <slot name="subtitle">
              Do you really want to {{ action }} this record? This process
              cannot be undone.
            </slot>
          </p>
        </div>
        <slot name="form"></slot>
        <div class="d-flex justify-center mb-4" style="gap: 25px">
          <v-btn
            large
            :color="confirmBtnColor"
            class="text-capitalize px-10"
            @click="onConfirmed"
            :loading="btnLoading"
          >
            <slot name="confirmBtnText">Yes</slot>
          </v-btn>
          <v-btn
            large
            color="primary"
            outlined
            class="text-capitalize px-10"
            @click="modalClosed"
          >
            <slot name="cancelBtnText">Cancel</slot>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    height: {
      type: String,
      default: "100px",
    },
    width: {
      type: String,
      default: "100px",
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      cnfDialog: "dialogs/confirmationDialog",
    }),

    show: {
      get() {
        return this.cnfDialog.show;
      },
      set(value) {
        this.toggleCnfDialog({ show: value });
      },
    },

    lottie() {
      return this.action;
    },

    action() {
      return this.cnfDialog.action;
    },

    btnLoading() {
      return this.cnfDialog.confirmBtnLoading;
    },

    confirmBtnColor() {
      if (this.action === "approve") return "success";
      if (["reject", "delete"].includes(this.action)) return "error";
      return `primary`;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  methods: {
    ...mapActions({
      toggleCnfDialog: "dialogs/toggleConfirmationDialog",
      setBtnLoading: "dialogs/setConfirmationDialogBtnLoading",
    }),

    openModal() {},

    modalClosed() {
      this.toggleCnfDialog({ show: false });
      this.$emit("cancelled");
    },

    onConfirmed() {
      this.$emit("confirmed");
      this.setBtnLoading({ loading: true });
    },
  },
};
</script>
