import { parsePhoneNumber } from "libphonenumber-js";
import moment from "moment";

const imageExtensions = [
  "gif",
  "jpg",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "png",
  "svg",
  "webp",
  "bmp",
  "ico",
];

const pdfExtensions = ["pdf"];

export default {
  debug(text) {
    console.log(text);
  },

  getDateFormat(date) {
    if (date) {
      return moment(date).format("D MMM YYYY");
    }
    return "";
  },

  getPhoneNumberInfo(number, country_code) {
    const phoneNumber = parsePhoneNumber(number, country_code);
    if (phoneNumber) {
      return {
        phone_number: phoneNumber.nationalNumber,
        phone_code: phoneNumber.countryCallingCode,
        country_code: phoneNumber.country,
      };
    } else return null;
  },

  getCurrentDate(currentDate) {
    return moment(currentDate).format("YYYY-MM-DD");
  },

  sleep(sec) {
    return new Promise((r) => setTimeout(r, sec * 1000));
  },

  getFilenameFromUrl(url) {
    return url.split("/").pop().split("?")[0].split("#")[0];
  },

  getFileTypeFromUrl(url) {
    // eslint-disable-next-line no-useless-escape
    const ext = String(url).split(".").pop().split(/\#|\?/)[0];

    if (imageExtensions.includes(ext)) return "image";
    if (pdfExtensions.includes(ext)) return "pdf";
    return null;
  },
};
