<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="600px"
    content-class="rounded-xl"
  >
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">Select your preference</div>
        <v-btn icon @click="modalClosed">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4">
        <div class="text-body-1">
          As you're becoming unavailable, Please select your preference for the
          {{ user.role == "operations_executive" ? "" : "leads and" }}
          cases currently assigned to you.
        </div>
        <v-radio-group v-model="unassign">
          <v-radio
            v-for="item in items"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></v-radio>
        </v-radio-group>
        <span v-if="case_list.length">
          <div class="ml-8 mt-n3" v-if="unassign">
            <b>Select Cases</b>
            <v-checkbox
              v-model="selectedCase"
              v-for="item in case_list"
              :key="item.id"
              :label="customCaseName(item)"
              :value="item.id"
              hide-details
              dense
            >
            </v-checkbox>
          </div>
        </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="my-2">
        <v-spacer />
        <v-btn
          class="text-capitalize px-5"
          height="42px"
          color="primary"
          :loading="btnLoading"
          @click="toggleAvailability"
        >
          Make me Unavailable
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      case_list: [],
      unassign: false,
      selectedCase: [],
      btnLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      availabilityDialog: "dialogs/availabilityDialog",
    }),
    show: {
      get() {
        return this.availabilityDialog.show;
      },
      set(value) {
        this.toggleAvailabilityDialog({ show: value });
      },
    },
    items() {
      if (this.user.user_type === this.$keys.OPERATIONS_EXECUTIVE) {
        return [
          { label: "Leave everything as it is", value: false },
          { label: "Unassign Open Cases", value: true },
        ];
      }

      if (this.user.user_type === this.$keys.SALES_EXECUTIVE) {
        return [
          { label: "Leave everything as it is", value: false },
          { label: "Unassign Open Leads and Cases", value: true },
        ];
      }

      return [];
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  methods: {
    ...mapActions({
      toggleAvailabilityDialog: "dialogs/toggleAvailabilityDialog",
      toggleConfirmationDialog: "dialogs/toggleConfirmationDialog",
      setBtnLoading: "dialogs/setConfirmationDialogBtnLoading",
    }),

    openModal() {
      this.getCaseList();
    },

    modalClosed() {
      this.unassign = false;
      this.selectedCase = [];
      this.toggleAvailabilityDialog({ show: false });
      this.$emit("dialog-closed");
    },

    getCaseList() {
      const onSuccess = (res) => {
        this.case_list = res.data.data;
      };

      let params = {
        list_type: "unassign",
      };

      return this.$request(this.$keys.GET, this.$urls.case.list, {
        params,
        onSuccess,
      });
    },

    toggleAvailability() {
      this.btnLoading = true;

      const onSuccess = () => {
        this.modalClosed();
      };

      const onFinally = () => {
        this.btnLoading = false;
      };

      const data = {
        availability: this.$keys.UNAVAILABLE,
        unassign: this.unassign,
        cases: this.selectedCase,
      };

      return this.$request(
        this.$keys.POST,
        this.$urls.team.changeAvailability,
        {
          data,
          onSuccess,
          onFinally,
        }
      );
    },

    customCaseName(item) {
      return `${item.service.name} (${item.case_id})`;
    },
  },
};
</script>
<style scoped></style>
