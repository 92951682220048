<template>
  <v-app-bar
    app
    :class="$vuetify.breakpoint.xs ? '' : 'px-3'"
    height="60px"
    :color="$vuetify.theme.dark ? 'dark' : 'white'"
  >
    <v-app-bar-nav-icon
      @click.stop="$emit('toggleLeftNav')"
      v-if="$vuetify.breakpoint.xs"
    ></v-app-bar-nav-icon>
    <v-toolbar-title
      class="text-subtitle-1 primary--text text-capitalize font-weight-bold"
    >
      {{ user.user_type_display }} Portal
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <div class="d-flex align-center" style="gap: 20px">
      <v-btn
        outlined
        class="text-capitalize"
        text
        @click="toggleServiceTypeDialog({ show: true })"
        v-if="serviceTypeSwitch.show && service_types.length"
      >
        {{ service_types[serviceTypeSwitch.selected].value }}
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>

      <v-btn
        outlined
        text
        class="text-capitalize"
        @click="changeAvailability"
        v-if="
          [$keys.SALES_EXECUTIVE, $keys.OPERATIONS_EXECUTIVE].includes(
            user.user_type
          )
        "
      >
        <v-icon left v-if="user.availability === $keys.AVAILABLE" color="green">
          mdi-toggle-switch-outline
        </v-icon>
        <v-icon left v-else color="red">mdi-toggle-switch-off-outline</v-icon>
        {{ user.availability }}
      </v-btn>

      <!-- profile menu -->
      <v-menu
        v-model="notification.show"
        bottom
        left
        rounded
        offset-y
        nudge-bottom="10"
        origin="top right"
        transition="scale-transition"
        :close-on-content-click="false"
        @input="onMenuChange"
      >
        <!-- profile menu btn -->
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="primary"
            :content="notificationCount.unseen"
            :value="notificationCount.unseen"
            offset-x="16"
            offset-y="16"
          >
            <v-btn
              icon
              text
              outlined
              v-on="on"
              v-bind="attrs"
              height="43"
              width="43"
            >
              <v-icon>mdi-bell-outline</v-icon>
            </v-btn>
          </v-badge>
        </template>

        <!-- profile menu content -->
        <v-card>
          <v-card-title class="py-3 d-flex justify-space-between align-center">
            <div class="text-subtitle-1">Notifications</div>
            <div
              class="text-body-2 primary--text"
              v-if="notificationCount.unseen > 0"
            >
              {{ notificationCount.unseen }} New
            </div>
          </v-card-title>
          <v-divider />
          <div style="height: 500px; width: 400px; overflow-y: auto">
            <div
              v-if="notification.loading"
              class="d-flex align-center justify-center"
              style="height: 100%"
            >
              <v-progress-circular
                size="60"
                width="4"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else style="height: 100%">
              <v-list dense v-if="notification.data.length">
                <template v-for="notification in notification.data">
                  <v-list-item
                    :key="`notification-${notification.id}`"
                    @click="notificationClickHandler(notification)"
                  >
                    <!-- <v-list-item-avatar size="10" v-if="!notification.is_seen">
                      <v-icon color="primary"> mdi-circle </v-icon>
                    </v-list-item-avatar> -->
                    <v-list-item-content>
                      <v-list-item-subtitle
                        class="text-caption d-flex justify-space-between"
                      >
                        <div v-if="notification.case">
                          {{ notification.case.case_id }}
                        </div>
                        <div>
                          {{ notification.date_time }}
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-title class="d-flex justify-space-between">
                        <div>{{ notification.title }}</div>
                      </v-list-item-title>
                      <v-list-item-subtitle class="mt-1 text-wrap">
                        {{ notification.body }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="`divider-${notification.id}`"></v-divider>
                </template>
                <v-slide-y-reverse-transition>
                  <div
                    class="mt-2 d-flex justify-center"
                    v-if="notification.page_number !== notification.page_total"
                  >
                    <v-btn
                      color="primary"
                      small
                      class="text-capitalize"
                      @click="notificationLoadMore"
                      :loading="notification.loadMoreLoading"
                    >
                      <v-icon left small>mdi-arrow-down</v-icon>load more
                    </v-btn>
                  </div>
                </v-slide-y-reverse-transition>
              </v-list>
              <div
                class="d-flex align-center justify-center"
                style="height: 100%"
                v-else
              >
                <vue-lottie
                  :animationData="
                    require('@/assets/lotties/no-notifications.json')
                  "
                  :loop="true"
                />
              </div>
            </div>
          </div>
        </v-card>
      </v-menu>

      <!-- profile menu -->
      <v-menu
        bottom
        left
        min-width="200px"
        rounded
        offset-y
        nudge-bottom="10"
        origin="top right"
        transition="scale-transition"
      >
        <!-- profile menu btn -->
        <template v-slot:activator="{ on }">
          <v-btn icon text outlined v-on="on" height="43" width="43">
            <v-avatar>
              <v-img :src="user.profile_pic" v-if="user.profile_pic" />
              <span v-else class="primary--text font-weight-bold">
                {{ user.name_initials }}
              </span>
            </v-avatar>
          </v-btn>
        </template>

        <!-- profile menu content -->
        <v-card>
          <div class="pa-4 d-flex align-center" style="gap: 13px">
            <!-- profile pic -->
            <v-avatar color="primary">
              <v-img :src="user.profile_pic" v-if="user.profile_pic" />
              <span v-else class="white--text font-weight-bold">
                {{ user.name_initials }}
              </span>
            </v-avatar>

            <div>
              <!-- user name -->
              <div class="font-weight-medium">
                {{ user.full_name }}
              </div>

              <!-- user mobile number -->
              <div v-if="user.mobile" class="text-caption">
                {{ user.mobile_display }}
              </div>

              <!-- user email address-->
              <div v-if="user.email" class="text-caption mt-n1">
                {{ user.email }}
              </div>
            </div>
          </div>

          <v-divider />

          <div class="pa-4">
            <div class="d-flex flex-column" style="gap: 10px">
              <!-- password change btn -->
              <v-btn
                rounded
                outlined
                color="primary"
                @click="togglePasswordChangeDialog({ show: true })"
              >
                <v-icon left>mdi-lock-reset</v-icon>
                Change Password
              </v-btn>

              <!-- logout btn -->
              <v-btn rounded outlined color="primary" @click="logoutHandler">
                <v-icon left>mdi-logout-variant</v-icon>
                Logout
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-menu>

      <!-- setting drawer btn -->
      <v-btn
        icon
        text
        outlined
        @click="toggleSettingsDrawer({ show: true })"
        height="43"
        width="43"
      >
        <v-icon>mdi-tune-vertical</v-icon>
      </v-btn>
    </div>

    <password-change-dialog />
    <availability-dialog @dialog-closed="getUserProfile" />
    <service-type-dialog />

    <ConfirmationDialog @confirmed="onConfirmed" height="130px" width="130px">
      <template v-slot:subtitle>
        Do you really want to make yourself available.
      </template>
      <template v-slot:confirmBtnText> Yes, Make Available </template>
    </ConfirmationDialog>
  </v-app-bar>
</template>

<script>
import firebase from "firebase/app";
import { mapActions, mapGetters } from "vuex";
import authToken from "@/common/authToken";

import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";
import PasswordChangeDialog from "@/components/Dialogs/PasswordChangeDialog";
import AvailabilityDialog from "@/components/Dialogs/AvailabilityDialog";
import ServiceTypeDialog from "@/components/Dialogs/ServiceTypeDialog";

export default {
  components: {
    PasswordChangeDialog,
    AvailabilityDialog,
    ConfirmationDialog,
    ServiceTypeDialog,
  },
  data() {
    return {
      drawer: false,
      drawerMini: false,
      notification: {
        show: false,
        loading: false,
        page_total: 1,
        page_number: 1,
        loadMoreLoading: false,
        data: [],
      },
    };
  },

  computed: {
    ...mapGetters({
      logo: "getLogo",
      user: "getUser",
      service_types: "getServiceTypes",
      notificationCount: "topNav/notificationCount",
      serviceTypeSwitch: "topNav/serviceTypeSwitch",
    }),
  },

  mounted() {
    this.getNotificationCount();
  },

  methods: {
    ...mapActions({
      setUser: "setUser",
      toggleAvailabilityDialog: "dialogs/toggleAvailabilityDialog",
      togglePasswordChangeDialog: "dialogs/togglePasswordChangeDialog",
      toggleSettingsDrawer: "drawers/toggleSettingsDrawer",
      toggleConfirmationDialog: "dialogs/toggleConfirmationDialog",
      setConfirmationDialogBtnLoading:
        "dialogs/setConfirmationDialogBtnLoading",
      toggleServiceTypeDialog: "dialogs/toggleServiceTypeDialog",
      getNotificationCount: "topNav/getNotificationCount",
    }),

    goToDashboard() {
      this.$router.push({ name: "dashboard" });
    },

    getUserProfile() {
      const successHandler = (res) => {
        this.setUser(res.data.data);
      };
      return this.$request(this.$keys.GET, this.$urls.auth.profile, {
        onSuccess: successHandler,
      });
    },

    changeAvailability() {
      if (this.user.availability === this.$keys.AVAILABLE) {
        this.toggleAvailabilityDialog({ show: true });
      } else {
        this.toggleConfirmationDialog({ show: true });
      }
    },

    onConfirmed() {
      const onSuccess = () => {
        this.getUserProfile();
        this.toggleConfirmationDialog({ show: false });
      };

      const onFinally = () => {
        this.setConfirmationDialogBtnLoading({ loading: false });
      };

      const data = {
        availability: this.$keys.AVAILABLE,
      };

      return this.$request(
        this.$keys.POST,
        this.$urls.team.changeAvailability,
        {
          data,
          onSuccess,
          onFinally,
        }
      );
    },

    logoutHandler() {
      if (firebase.messaging.isSupported()) {
        this.$fcm
          .getToken({ vapidKey: process.env.VUE_APP_FCM_KEY })
          .then((token) => {
            this.$fcm
              .deleteToken(token)
              .then(() => {
                authToken.removeToken();
                this.$router.push({ name: "login" });
              })
              .catch(() => {
                authToken.removeToken();
                this.$router.push({ name: "login" });
              });
          })
          .catch(() => {
            authToken.removeToken();
            this.$router.push({ name: "login" });
          });
      } else {
        authToken.removeToken();
        this.$router.push({ name: "login" });
      }
    },

    getNotificationList({ loadMore = false } = {}) {
      if (!loadMore) {
        this.notification.loading = true;
      }

      const onSuccess = (res) => {
        this.notification.data = [...this.notification.data, ...res.data.data];
        this.notification.page_total = res.data.page_info.page_total;
        this.notification.page_number = res.data.page_info.page_number;
      };

      const onFinally = () => {
        this.notification.loading = false;
      };

      const params = {
        page_number: this.notification.page_number,
      };

      return this.$request(this.$keys.GET, this.$urls.notification.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    async notificationLoadMore() {
      this.notification.page_number++;
      this.notification.loadMoreLoading = true;
      await this.getNotificationList({ loadMore: true });
      this.notification.loadMoreLoading = false;
    },

    notificationClickHandler(notification) {
      this.notification.show = false;
      if (notification.case) {
        this.$router
          .push({
            name: "caseDetail",
            params: {
              id: notification.case.id,
            },
          })
          .catch(() => {});
      }
    },

    onMenuChange(value) {
      this.notification.data = [];
      this.notification.page_number = 1;
      this.notification.page_total = 1;
      if (value) {
        this.getNotificationList();
      }
    },
  },
};
</script>