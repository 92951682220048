const authToken = {
  setToken: (accessToken = null, refreshToken = null) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
    }
    if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
    }
  },
  getToken: () => {
    return {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
    };
  },
  removeToken: () => {
    // get theme and theme mode from local store
    const theme = localStorage.getItem("theme");
    const theme_mode = localStorage.getItem("theme_mode");

    // clear local store
    localStorage.clear();

    // set theme and theme mode to local store
    localStorage.setItem("theme", theme);
    localStorage.setItem("theme_mode", theme_mode);
  },
};
export default authToken;
