<template>
  <div class="main-div d-flex justify-center align-center">
    <div class="background">
      <div class="blur"></div>
    </div>
    <v-card
      width="500"
      height="100%"
      class="pa-4"
      :outlined="$vuetify.theme.dark"
      rounded="xl"
    >
      <v-card-title class="d-flex justify-center pt-10">
        <img
          :src="require(`@/assets/images/${logo.lg}`)"
          alt=""
          width="260px"
        />
      </v-card-title>
      <v-card-text>
        <router-view></router-view>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      logo: "getLogo",
    }),
  },
};
</script>
<style scoped>
.main-div {
  min-height: 100%;
}
.background {
  background-image: url("https://img.freepik.com/free-photo/book-with-green-board-background_1150-3837.jpg");
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.blur {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(6px);
  height: 100%;
  width: 100%;
}
</style>
