<template>
  <v-navigation-drawer
    v-model="show"
    @keydown.esc="closeDrawer"
    right
    temporary
    hide-overlay
    app
    floating
    width="340"
  >
    <v-toolbar elevation="0" height="60px">
      <v-toolbar-title>Preferences</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDrawer">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container>
      <h4 class="mb-1">Preferred Mode</h4>
      <div class="d-flex flex-row" style="gap: 10px">
        <v-btn
          depressed
          :color="themeMode === mode.key ? 'primary' : ''"
          v-for="mode in themeModes"
          :key="mode.key"
          @click="changeThemeMode(mode.key)"
        >
          <span class="text-capitalize">{{ mode.title }}</span>
          <v-icon right>{{ mode.icon }}</v-icon>
        </v-btn>
      </div>
    </v-container>
    <v-container>
      <h4 class="mb-1">Preferred Theme</h4>
      <div class="d-flex flex-wrap justify-space-between" style="gap: 15px">
        <v-btn
          depressed
          v-for="theme in themes"
          :key="theme.key"
          :color="theme[$vuetify.theme.dark ? 'dark' : 'light'].primary"
          @click="changeTheme(theme.key)"
          height="50"
          width="50"
        >
          <v-icon v-if="theme.key === themeColor" color="white" size="30"
            >mdi-check</v-icon
          >
        </v-btn>
      </div>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import themes from "@/common/themes";

export default {
  name: "SettingsDrawer",
  data() {
    return {
      themeModes: [
        { key: "light", title: "Light", icon: "mdi-white-balance-sunny" },
        { key: "dark", title: "Dark", icon: "mdi-weather-night" },
        { key: "system", title: "System", icon: "mdi-desktop-tower-monitor" },
      ],
      themes: themes,
    };
  },
  computed: {
    ...mapGetters({
      themeMode: "getThemeMode",
      themeColor: "getTheme",
      settingsDrawer: "drawers/settingsDrawer",
    }),
    show: {
      get() {
        return this.settingsDrawer.show;
      },
      set(value) {
        this.toggleSettingsDrawer({ show: value });
      },
    },
  },
  methods: {
    ...mapActions({
      setThemeMode: "setThemeMode",
      setTheme: "setTheme",
      toggleSettingsDrawer: "drawers/toggleSettingsDrawer",
    }),

    closeDrawer() {
      this.toggleSettingsDrawer({ show: false });
    },

    changeThemeMode(mode) {
      this.setThemeMode(mode);
      localStorage.setItem("theme_mode", mode);
    },

    changeTheme(theme_key) {
      this.setTheme(theme_key);
    },
  },
};
</script>